<template>
	<q-card :class="$q.screen.gt.xs ? 'q-ma-xl' : 'q-ma-sm'" class="q-pa-md">
		<q-stepper
			v-model="step"
			ref="stepper"
			color="primary"
			animated
			flat
			:alternative-labels="$q.screen.gt.xs ? false : true"
		>
			<q-step
				:name="1"
				:done="step > 1"
				:title="'Choose ' + module_name + ' form'"
			/>
			<q-step
				:name="2"
				:done="step > 2"
				:title="'Create ' + module_name"
			/>
	  </q-stepper>
		<q-card-section v-if="!display_fields" class="q-pa-none">
			<q-item class="q-pa-none">
				<q-select
					class="full-width"
					label="Select a form"
					v-model="form"
					:options="forms"
					:option-label="(item) => item.name"
					:error="has_form_error"
					:error-message="form_error_message"
					@input="executeLoader"
				/>
			</q-item>
			<q-item v-if="selected_item" class="q-pa-none">
				<q-input
					class="full-width"
					outline
					v-model="title"
					label="Enter form title"
					clear-icon='close'
					:error="has_title_error"
					:error-message="title_error_message"
				/>
			</q-item>
			<q-card-actions align="right" class="q-pa-none">
				<q-btn flat label="Cancel" color="primary" :to="{ name: 'Start' }" />
				<q-btn flat	label="Continue" color="primary" @click="startIncident"/>
			</q-card-actions>

		</q-card-section>
		<q-card-section v-else-if="display_fields" class="q-pa-none">
			<Incident :incident="new_incident" />
		</q-card-section>
	</q-card>
</template>

<script>
import IncidentFormAPI from "@/services/api/IncidentForm.js";
import Incident from "@/components/incidents/incidents/Incident.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "IncidentCreate",
	components: {
		Incident,
	},
	data () {
		return {
			forms: [],
			form: null,
			title: '',
			data: {},
			selected_item: '',
			has_form_error: false,
			has_title_error: false,
			form_error_message: '',
			title_error_message: '',
			display_fields: false,
			step: 1,
		}
	},
	computed: mapGetters(['current_CG', 'new_incident', 'module_name']),
	methods: {
		...mapActions(['createIncident']),
		async startIncident(){
			this.resetValues()
			if (!this.form){
				this.has_form_error = true
				this.form_error_message = 'Please select a form'
				return
			}
			if(!this.title){
				this.has_title_error = true
				this.title_error_message = 'Please enter a form title'
				return
			}
			this.data = {'id' : this.form.id, 'title': this.title}
			this.$q.loading.show({
				delay: 400,
			})
			this.createIncident(this.data)
			this.step += 1
			this.$q.loading.hide()
			this.display_fields = true

		},
		executeLoader(selected_item){
			if(!selected_item){
				this.title = "";
				this.selected_item = "";
			}else{
				this.selected_item = selected_item.name
				this.title = this.selected_item
			}
		},
		resetValues(){
			this.has_form_error = this.has_title_error = false
			this.form_error_message = this.title_error_message = ''
		}
	},
	async created() {
		let forms = await IncidentFormAPI.getForms();
		if (this.current_CG.customer_group.parent) {
			for (let form of forms) {
				if (form.customer_group === this.current_CG.customer_group.id) {
					this.forms.push(form)
				}
			}
		}
		else {
			this.forms = forms
		}
		if (this.forms.length == 1){
			this.form = this.forms[0]
			this.selected_item = this.form
			this.title = this.selected_item.name
		}
	},
};
</script>
<style lang="scss">
	.q-stepper__step-inner {
		display: none;
	}
	.q-stepper__tab{
		padding: 0px;
	}
</style>